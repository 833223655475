<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- FORM START -->
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="name" label="Name" v-model="name"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
        </div>
      </div>
      <!-- FORM END -->
      <small class="ml-2">List Menu</small>
      <vs-table multiple v-model="menu_id" :data="menus" @selected="handleSelected" class="mb-3">
          <template slot="thead">
            <vs-th>Menu</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr.id" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].name"><pre v-html="data[indextr].name"></pre></vs-td>
            </vs-tr>
          </template>
      </vs-table>
    </div>
    <div class="vx-col w-full flex">
      <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>&nbsp;
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  props:{
    id: {
      type: String,
      default: null
    }
  },
  data(){
    return {
      name: "",
      menu_id: []
    }
  },
  computed:{
    ...mapState({
      menus: state => state.roles.all_menu
    })
  },
  methods:{
    ...mapActions({
      dispatchStore: 'roles/store',
      dispatchUpdate: 'roles/update',
      dispatchShow: 'roles/show',
      dispatchShowAllMenu: 'roles/showAllMenu'
    }),
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        
        let payload = {
          id: this.id,
          name: this.name,
          menu_id: this.menu_id
        }
        try {
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
            this.$emit('on-success', data)            
          } else {
            let {data} = await this.dispatchStore(payload)
            this.$emit('on-success', data)
          }
        } catch (error) {
          this.$vs.notify({
            title: 'Oops!',
            text: error.data.message,
            color: 'danger'
          })
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      this.name = data.role.name
      this.menu_id = data.menu_id
    },
    async getDataMenu(){
      await this.dispatchShowAllMenu()
    },
    handleSelected(id) {
      if(this.menu_id.includes(id)) {
         this.menus.forEach(obj => {
          if(obj.id == id) {
            let path = obj.path.split(',');
            let concatMenu = this.menu_id.concat(path);
            let setMenu = new Set(concatMenu);
            let unionMenu = [];
            for(let element of setMenu) {    
                unionMenu.push(element); 
            }
            this.menu_id = unionMenu;
            this.addMenuChildRecursive(obj.id);
            return;
          }
        });
      }
      else {
        this.menus.forEach(obj => {
          if(obj.id == id) {

            let index = this.menu_id.indexOf(id);
            if (index > -1) {
              this.menu_id.splice(index, 1);
            }

            this.removeMenuChildRecursive(obj.id);
            
            let path = obj.path.split(',');
            let checkFriend = false;
            
            this.menus.forEach(objB => {
              if(objB.parent_id == obj.parent_id) {
                if(this.menu_id.includes(objB.id)) {
                  checkFriend = true;
                  return;
                }
              }
            });

            if(!checkFriend) {
              for(let i = path.length - 1; i >= 0; i--) {
                let index = this.menu_id.indexOf(path[i]);
                if (index > -1) {
                  this.menu_id.splice(index, 1);
                }
                checkFriend = false;
                  this.menus.forEach(objC => {
                    if(objC.id == path[i]) {
                      this.menus.forEach(objD => {
                        if(objD.parent_id == objC.parent_id) {
                          if(this.menu_id.includes(objD.id)) {
                            checkFriend = true;
                            return;
                          }
                        }
                      });
                    }
                  });
                if(checkFriend) break;
              }
            }
            return;
          }
        });
      }
    },
    addMenuChildRecursive(id) {
        this.menus.forEach(obj => {
          if(obj.parent_id == id) {
            // let path = obj.path.split(',');
            // let concatMenu = this.menu_id.concat(path);
            // let setMenu = new Set(concatMenu);
            // let unionMenu = [];
            // for(let element of setMenu) {    
            //     unionMenu.push(element); 
            // }
            // this.menu_id = unionMenu;

            this.menu_id.push(obj.id);
            this.addMenuChildRecursive(obj.id);
          }
        });
    },
    removeMenuChildRecursive(id) {
        this.menus.forEach(obj => {
          if(obj.parent_id == id) {
            let index = this.menu_id.indexOf(obj.id);
            if (index > -1) {
              this.menu_id.splice(index, 1);
            }
            this.removeMenuChildRecursive(obj.id);
          }
        });
    }
  },
  mounted(){
    // console.log(this.ids)
    this.getDataMenu();
    
    if (this.id) {
      this.getDetail()
    }
  }
}
</script>

<style>

</style>